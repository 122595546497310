.App {
  /* text-align: center; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo-animation-1 {
  animation: updown 1s ease;
}
.logo-animation-2 {
  animation: updown 1s ease;
  animation-delay: 0.2s;
}
.logo-animation-3 {
  animation: updown 1s ease;
  animation-delay: 0.5s;
}

@keyframes updown {
  0% {
    transform: translateY(-800%);
  }
  100% {
    transform: translateY(0%);
  }
}
